/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import style from "./statistic.module.css";
import CountUp from 'react-countup';
import { useState, useEffect } from 'react';

const useOnScreen = (ref, rootMargin = "0px") => {
    // State and setter for storing whether the element is visible
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when the observer notices the intersection
                setIntersecting(entry.isIntersecting);
            },
            {
                rootMargin
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.unobserve(ref.current);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return isIntersecting;
};

export default function Statistic() {
    const ref = useRef(); // Create a ref for the container
    const isVisible = useOnScreen(ref); // Use the custom hook

    return (
        <>
            <div style={{ backgroundColor: '#0e5461', marginTop: '70px' }} ref={ref}>
                <div className={`${style.statsContainer} container`}>
                    <div className={style.statItem}>
                        <h1><CountUp end={isVisible ? 5150000 : 0} prefix="USD " className='counter' duration={3.5} /></h1>
                        <p>The average total cost of a data breach per year</p>
                    </div>
                    <div className={style.statItem}>
                        <h1><CountUp end={isVisible ? 1650000 : 0} prefix="USD " className='counter' duration={3.5} /></h1>
                        <p>Cost savings achieved by organizations with high levels of IR planning and testing</p>
                    </div>
                    <div className={style.statItem}>
                        <h1><CountUp end={isVisible ? 87 : 0} suffix="%" className='counter' duration={3.5} /></h1>
                        <p>The percentage of breaches that involved data stored in the cloud - public, private or multiple environments</p>
                    </div>
                </div>
            </div>
        </>
    );
}
