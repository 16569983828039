import img1 from '../../../assets/aboutUs/bottom1.webp';
import img2 from '../../../assets/aboutUs/bottom21.webp'

const fakeData = [
    {
        id: 1,
        heading: 'Empowering Businesses Globally: Cyber TechGate, Your Trusted Partner Across Continents, Serving, New Zealand, and India',
        img: img1,
        content: 'Established in 2022, Cyber TechGate emerged as a small business with a clear objective: to provide accessible executive leadership and advanced security engineering solutions to organizations of all sizes. Leveraging strategic distribution and partner channels, Cyber TechGate delivers comprehensive cybersecurity, risk management, and compliance programs tailored to suit the unique requirements of clients in India and New Zealand, spanning from burgeoning Tech Startups to established Fortune 100 enterprises. Notably, Cyber TechGate proudly contributes to shaping industry standards as an original contributor to the NIST Cybersecurity Framework (CSF) and the American National Standards Institute (ANSI) ISO/IEC 27001'
    },
    {
        id: 2,
        heading: 'Mission',
        img: img2,
        content: 'Our mission is to establish ourselves as a leading force in the cybersecurity realm, committed to ethical hacking and proactive defense strategies. We aim not only to earn respect but also to wield significant influence globally.||Our dedication extends to surpassing client expectations consistently, enhancing their operational security, fostering growth, and delivering unparalleled value in every service we offer.Through our unwavering commitment to excellence, we strive to emerge as pioneers in our field, setting new standards and shaping the future of cybersecurity.||Central to our mission is fostering an environment of mutual respect, where each team members contributions are valued and acknowledged. We believe in nurturing talent, encouraging innovation, and cultivating a culture of continuous learning to stay ahead in an ever-evolving landscape of cyber threats.'
    },
]
export default fakeData;