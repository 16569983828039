import React from "react";
import styles from "./TeamHome.module.css";
import { 
    FaLinkedin,
    //  FaPhone 
    } from "react-icons/fa";

const TeamHomeId = (props) => {
    return (
        <div className={styles.box}>
            <img src={props.icon} alt="" className={styles.img} />
            <p className={styles.heading}>{props.heading}</p>
            <p className={styles.text}>{props.content}</p>
            <span className="d-flex justify-content-center gap-4">
                {/* <FaPhone color="#6610f2" style={{ width: '25px', height: "22px" }} /> */}
                <a href={props.to} target="_blank" rel="noopener noreferrer">
                    <FaLinkedin className={styles.iconColor} />
                </a>
            </span>
        </div>
    );
};
export default TeamHomeId;
