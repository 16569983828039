import React from "react";
import styles from './Footer.module.css';
import fb from '../../assets/home/facebook.png';
import linkdin from '../../assets/home/linkdin.png';
import insta from '../../assets/home/insta.png';

const Footer = () => {
    return (
        <>
            <div style={{ backgroundColor: '#2b2b2b', 
            paddingTop: '89px', 
            paddingBottom: '90px', 
            boxShadow:"2px 3px 10px 5px #fff",
            borderRadius:'10px',
            margin:'5px'
            }}>
                <div className="container" >
                    <div className="row d-flex justify-content-between">
                        <div className={`col-lg-3 col-sm-6 ${styles.contain}`}>
                            <p className={styles.logo}>Cyber<span style={{ color: "white" }}>TechGate</span></p>
                            <p className={styles.text}>Empowering your digital world with comprehensive cyber security expertise.</p>
                            <p className={styles.text}>Securing tomorrow's innovations, today</p>
                            <div className={styles.social_logo}>
                                <img src={fb} alt="fb" className={styles.img} />
                                <a href="http://www.linkedin.com/in/rajtucker" target="_blank" rel="noopener noreferrer">
                                    <img src={linkdin} alt="in" className={styles.img} />
                                </a>
                                <img src={insta} alt="insta" className={styles.img} />
                            </div>
                        </div>

                        <div className={`col-lg-3 col-sm-6 ${styles.contain}`}>
                            <p className={styles.head}>Offices</p>
                            <p className={styles.sub_head}>Auckland Office</p>
                            <p className={styles.text}>3205a Great North Road, New Lynn, Auckland, 0600, NZ</p>
                            {/* <p className={styles.text}>rajtucker@cybertechgate.com</p>
                            <p className={styles.text_last}>+64 210 2533922</p> */}

                            <p className={styles.sub_head}>Wellington office</p>
                            <p className={styles.text}>11A Tully street, Kilbirnie-6022 Wellington, New Zealand</p>
                            {/* <p className={styles.text}>shahbaz@cybertechgate.com</p>
                            <p className={styles.text}>+64 21 2594363</p> */}
                        </div>

                        <div className={`col-lg-3 col-sm-6 ${styles.contain}`}>
                            <p className={styles.head}>Opening Hour</p>
                            <p className={styles.text}>Mon - Sat : 8:00 - 17:00</p>
                        </div>
                    </div>

                </div>
            </div>


            <div style={{ backgroundColor: '#2b2b2b', borderTop: 'solid 1px #707070' }}>
                <p className={styles.bottom}>Copyright © 2024 Cyber TechGate. All right reserved</p>
            </div>
        </>
    )
}
export default Footer;