import React from 'react'
import styles from './sector.module.css';
import banking from "../../assets/IndustrySector/banking.png"
import Education from "../../assets/IndustrySector/education.png"
import government from "../../assets/IndustrySector/government.png"
import Health from "../../assets/IndustrySector/healthcare.png"
import Software from "../../assets/IndustrySector/software.png"
import Transport from "../../assets/IndustrySector/transport.png"

export default function IndustrySector() {
    return (
        <>
            <div className="container overflow-hidden">
                <p data-aos='slide-right' data-aos-offset="220" className={styles.heading}>Industries We Cater To</p>
                <p data-aos='slide-left' className={styles.sub_text}>At Cyber TechGate, we boast a formidable history of delivering discreet and confidential IT security solutions to a diverse array of government and commercial entities across New Zealand. Specializing in data protection and IT security services, we guarantee the fortification of your business IT landscapes.</p>
                <div data-aos='slide-right' className={`${styles.iconState} d-flex justify-content-center align-items-center`}>
                    <span className={`${styles.sectors} d-flex flex-column align-items-center`}>
                        <img src={banking} alt='banking' className={styles.imageIcon} />
                        <p>Banking</p>
                    </span>
                    <span className={`${styles.sectors} d-flex flex-column align-items-center`}>
                        <img src={government} alt='government' className={styles.imageIcon} />
                        <p>Government</p>
                    </span>
                    <span className={`${styles.sectors} d-flex flex-column align-items-center`}>
                        <img src={Education} alt='Education' className={styles.imageIcon} />
                        <p>Education</p>
                    </span>
                    <span className={`${styles.sectors} d-flex flex-column align-items-center`}>
                        <img src={Transport} alt='Transport' className={styles.imageIcon} />
                        <p>Transport</p>
                    </span>
                    <span className={`${styles.sectors} d-flex flex-column align-items-center`}>
                        <img src={Software} alt='Software' className={styles.imageIcon} />
                        <p>Software</p>
                    </span>
                    <span className={`${styles.sectors} d-flex flex-column align-items-center`}>
                        <img src={Health} alt='Health' className={styles.imageIcon} />
                        <p>Health Care</p>
                    </span>
                </div>
            </div>
        </>
    )
}
