import securityEdit from '../../assets/home/securityEdit.png';
import CloudSecurity from '../../assets/home/CloudSecurity.png';
import incident from '../../assets/home/incident.png';
import PolicySystem from '../../assets/home/PolicySystem.png';
import penetration from '../../assets/home/penetration.png';
import consultancy from '../../assets/home/consultancy.png';

const data = [
    {
        id: 1,
        heading: 'IT Security Audit',
        content: 'Evaluate the complexity and variability of your systems functionality and their management strategies.',
        to: '/',
        icon: securityEdit,
    },
    {
        id: 2,
        heading: 'IT Policy System',
        content: 'Swiftly implement extensive IT policies aligned with benchmark industry norms.',
        to: '/',
        icon: PolicySystem,
    },
    {
        id: 3,
        heading: 'Incident Response',
        content: 'What measures has your organization put in place to respond to a malevolent event?',
        to: '/',
        icon: incident,
    },
    {
        id: 4,
        heading: 'Cloud Security',
        content: 'Grasp the hazards and bolster your defensive stance.',
        to: '/',
        icon: CloudSecurity,
    },
    {
        id: 5,
        heading: 'Penetration Testing',
        content: 'Utilizing ethical hacking to pinpoint your actual risks in the real world.',
        to: '/',
        icon: penetration,
    },
    {
        id: 6,
        heading: 'Cyber Scurity Consultancy',
        content: 'Our firm offers a wide array of IT security solutions across New Zealand.',
        to: '/',
        icon: consultancy,
    },
]

export default data;