import React from "react";
import styles from './Banner.module.css';
import { Link } from "react-router-dom";

const Banner = () => {

  return (
    <>
        <div className={styles.imgbox}>
            <div className={`${styles.center} overflow-hidden`}>
          <p data-aos='fade-down' className={styles.text}>Where Expertise meets Excellence</p>
          <button data-aos='fade-up' className={`btn custom_btn ${styles.btn}`}>
            <Link to={"/contactUs"} style={{ textDecoration: "none", color: "#fff" }}> LET&apos;S TALK</Link>
            </button>
            </div>
            
        </div>
        
    </>
  );
};

export default Banner;
