import React from "react";
import styles from './ChoosingUs.module.css';
import experience from '../../assets/home/experiences.png';
import communication from '../../assets/home/data_Privacy.png';
import comfort from '../../assets/home/certified.png';

const ChoosingUs = () =>{
    return(
        <div style={{ backgroundColor: '#0e5461'}}>
            <div className="container overflow-hidden">
                <p data-aos='slide-right' data-aos-offset="220" className={styles.heading}>Top three reasons why you should choosing us.</p>
                <p data-aos='slide-left' className={styles.sub_text}>Elevate your security with our expertise, privacy focus, and industry acclaim.</p>
                <div className={`row ${styles.sub}`}>
                    <div data-aos='fade-right' className="col-md-4">
                        <img src={experience} alt="experience"/>
                        <p className={styles.logo_head}>Experience</p>
                        <p className={styles.logo_text}>Harnessing years of battle-tested expertise to fortify your digital defenses and navigate the ever-evolving cyber landscape with confidence.</p>
                    </div>

                    <div data-aos='fade' className="col-md-4">
                        <img src={communication} alt="Data Privacy"/>
                        <p className={styles.logo_head}>Data Privacy</p>
                        <p className={styles.logo_text}>Championing your data sovereignty like a guardian of digital trust, ensuring every bit and byte remains under the impenetrable cloak of security.</p>
                    </div>

                    <div data-aos='fade-left' className="col-md-4">
                        <img src={comfort} alt="Industry Certified"/>
                        <p className={styles.logo_head}>Industry Certified</p>
                        <p className={styles.logo_text}>Verified excellence meets rigorous standards, assuring you a shield forged in the fires of industry acclaim and recognition.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChoosingUs;