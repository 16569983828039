
export const fakeData = [
    {
        id: 1,
        // img: image1,
        name: 'Erick Williom',
        profession: 'Technical Lead',
        message: "Since partnering with Cyber TechGate, our data has never felt safer. Their cybersecurity solutions are top-notch!"
    },
    {
        id: 2,
        // img: image2,
        name: 'Morris Mark',
        profession: 'Public Relations Specialist',
        message: "Impressive expertise and proactive approach. Cyber TechGate ensures our systems are always secure."
    },
    {
        id: 3,
        // img: image3,
        name: 'Michael Thompson',
        profession: 'IT Manager',
        message: "Highly recommended! Cyber TechGate keeps our sensitive information protected with their comprehensive cybersecurity solutions."
    },
    {
        id: 4,
        // img: image1,
        name: 'Emily Davis',
        profession: 'Finance Manager',
        message: "Exceptional service! Cyber TechGate's tailored approach and cutting-edge technology have kept our data safe and secure."
    },
    // {
    //     id: 5,
    //     img: image2,
    //     name: 'Miles Esther',
    //     profession: 'Public Relations Specialist',
    //     message: "You won't regret it. I was amazed at the quality of it. I am really satisfied with my it."
    // },
    // {
    //     id: 6,
    //     img: image3,
    //     name: 'Pena Norma',
    //     profession: 'Public Relations Specialist',
    //     message: "It fits our needs perfectly. Man, this thing is getting better and better as I learn more about it. You've saved our business!"
    // },
]