import React from "react";
import styles from './Real.module.css';
import improveImg from '../../assets/home/improveImg2.png';
import { Link } from "react-router-dom";

const Real = () =>{
    return(
        <div className="mb-5 overflow-hidden">
            <div className="row g-0">
                <div className={`col-md-6 ${styles.imgDiv}`}>
                    <img data-aos='slide-right' data-aos-offset="150" src={improveImg} alt="" className={styles.img}/>
                </div>
                <div className="col-md-6">
                    <div className={styles.text}>
                        <p data-aos='slide-right' data-aos-offset="150"  className={styles.heading}>Real</p>
                        <p data-aos='slide-left' data-aos-offset="150" className={styles.subHeading}>Improves Customer Experiences</p>
                        <p data-aos='zoom-in' data-aos-offset="150" className={styles.content}>
                            Enhancing customer experience through cyber security services ensures peace of mind and trust by safeguarding sensitive data and preserving privacy.
                            By fortifying defenses against cyber threats, businesses can maintain seamless operations, delivering uninterrupted services and building customer confidence.</p>
                        <div data-aos='zoom-in' style={{textAlign:'left'}}>
                            <button className={`btn custom_btn ${styles.btn}`}>
                                <Link to={"/contactUs"} style={{textDecoration:"none", color:"#fff"}}> LET&apos;S TALK</Link>
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Real;