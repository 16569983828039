import itAudit from '../../assets/individualService/itAudit.webp';
import itAudit3 from '../../assets/individualService/itAudit3.jpeg';
import Privacy from '../../assets/individualService/Privacy.png';
import Privacy2 from '../../assets/individualService/Privacy2.jpeg';
import response from '../../assets/individualService/response.jpg';
import response2 from '../../assets/individualService/response2.jpg';
import cloud from '../../assets/individualService/cloud.jpg';
import Penetration from '../../assets/individualService/Penetration.webp';
import Consultancy from '../../assets/individualService/Consultancy.webp';
import Consultancy2 from '../../assets/individualService/Consultancy2.webp';
const data = [
    {
        id: 1,
        heading: 'IT Security Audit',
        content: 'Our team is dedicated to assessing your organization security against potential threats within the constantly evolving tech environment.',
        contents: [
            {
                heading: 'Comprehensive Evaluation of Systems and Networks',
                img: itAudit,
                content: 'Cybersecurity threats emerge through diverse means, encompassing both human and automated activities. A standard IT security audit rigorously scrutinizes servers, network infrastructures, physical setups, operational methodologies, and conducts penetration testing. Our audit toolkit enables real-time, active testing of environments utilizing Platform as a Service (PaaS) and Infrastructure as a Service (IaaS) cloud services, applicable to both hybrid and entirely cloud-based models.||This audit, quoted at a fixed price, includes a two- day on - site exploration phase.Following this, a comprehensive report is delivered, highlighting identified vulnerabilities and proposing strategies for mitigation.'
            },
            {
                img: itAudit3,
                content: 'The audit methodology employed by Cyber TechGate involves a detailed, practical examination of your systems functionality and management practices.It evaluates the safeguarding measures relative to the potential risks facing the organization.|| The scrutiny extends to policies, procedures, overarching management, and other preventive measures to determine adherence to corporate standards.||A detailed report is then provided to the client, pinpointing critical areas of concern.With IT Security risk management becoming increasingly critical for major corporations and the public sector, auditing serves to close the compliance gap by identifying the vulnerabilities where the organization is most exposed.||There may be instances where existing IT policies are sufficient to counteract identified risks, yet compliance is lacking.Alternatively, there might be a need to formulate new policies and procedures to bridge security gaps for future protection.Technical security audits offer customization options to focus on particular concerns, should the client suspect specific vulnerabilities within their IT framework.'
            },
        ]
    },
    {
        id: 2,
        heading: 'IT Policy System',
        content: 'Crafting a Secure Business Computing Environment',
        contents: [
            {
                heading: 'Get The Maximum Benefits',
                img: Privacy,
                content: 'The initial step in fortifying a computing environment entails crafting meticulous policies and procedures that precisely outline the management and governance of digital information.||To guarantee effectiveness, Information Systems Security Policies should be written in a straightforward and understandable manner, enabling all stakeholders to grasp the requirements and obligations linked with the utilization of company information.||Protocol Policy Systems has curated a standardized set of policies, which are then individually customized to synchronize with the operational frameworks of your organization.'
            },
            {
                heading: 'Policy Administration as a Service',
                img: Privacy2,
                content: 'Our cloud-based subscription solution, Policy Management as a Service, is offered in three tiers - Lite, Essentials, and Premium. This service empowers organizations to promptly disseminate updated IT Security Policies within a timeframe of under five weeks, thereby obviating the overheads associated with internal policy creation and upkeep.||Our team of experts assumes the responsibility of acquiring specialized knowledge, crafting, and maintaining policies to ensure their relevance and alignment with standards such as ISO, NIST, and PCI- DSS on behalf of our clients.||With Policy Management as a Service in place, the process of engaging stakeholders and monitoring user interaction with the content becomes both effortless and transparent.'
            },
            {
                heading: 'Policy Organization within Policy Management as a Service',
                img: itAudit3,
                content: 'Policy Management as a Service encompasses a collection of 20 exhaustive policies addressing pivotal facets of information system utilization. These policies are meticulously articulated in simple and easily comprehensible English, supplemented with dropdown elucidations, pertinent standard references, and, where applicable, interlinked with statements in correlated policies. ||Furthermore, the policies are categorized for swift accessibility based on the roles of users, managers, or technical team members, thereby facilitating streamlined access to policies pertinent to their respective responsibilities.'
            },
        ]
    },
    {
        id: 3,
        heading: 'Incident Response',
        content: 'Cyber Forensics and Incident Handling',
        contents: [
            {
                heading: 'Strengthening Defenses Against DDoS and Ransomware Attacks',
                img: response2,
                content: 'In todays interconnected landscape, the internet has revolutionized business operations, offering unparalleled benefits. However, with constant connectivity and technological advancements, the door is wide open for malicious activities, turning cybercrime into a lucrative enterprise.||For most businesses, technology is indispensable, with their systems and data playing a pivotal role in day-to-day operations. Yet, the repercussions of a cybersecurity incident, be it a breach, DDoS attack, ransomware infiltration, or phishing scheme, can be severe and enduring, affecting financial stability, reputation, and operational integrity.||Preparedness is key in mitigating these risks. Having a robust incident response plan in place enables swift detection, protection, and mitigation strategies to be implemented. Each employee bears the responsibility of adhering to guidelines, ensuring the organization is equipped to safeguard customers, employees, and its brand in the event of a breach.'
            },
            {
                heading: 'Empowering Resilience with Comprehensive Incident Response Services',
                img: response,
                content: 'Implementing an incident response plan allows for efficient resource allocation and root cause identification, facilitating improvements in security posture to forestall future threats.||Recognizing the varying capabilities of organizations, Cyber TechGate offers a suite of Digital Forensics and Incident Response services, including:||1. Assisting in developing tailored incident response plans.||2. Assessing and validating existing plans through comprehensive testing.||3. Educating staff to heighten awareness and readiness against cyber threats.||4. Conducting thorough forensic investigations, encompassing evidence collection, assessment, conclusion, and courtroom presentation.'
            },
        ]
    },
    {
        id: 4,
        heading: 'Cloud Security',
        content: 'Launch party pitch technology user experience innovator buzz stealth MVP business model.',
        contents: [
            {
                heading: 'Navigating Cloud Security for Digital Transformation',
                img: Privacy2,
                content: 'Cloud technology offers unparalleled opportunities for businesses to boost efficiency, expand market reach, and cut costs. With digital transformation initiatives underway across industries, its crucial to understand and secure Cloud services effectively.||Cloud services typically fall into three main models:||1. SaaS(Software as a Service): Online applications like SalesForce or Dynamics 365, managed by providers, offer remote access without local infrastructure concerns.||2. PaaS(Platform as a Service): Datacenter providers maintain servers and operating systems, while organizations retain control over applications and data.||3. IaaS(Infrastructure as a Service): Organizations manage everything from the operating system upwards, with access to servers provided by datacenter providers.||At Cyber TechGate, we specialize in tailoring security solutions for Cloud environments, ensuring robust protection for data, networks, and systems.||Our services include:||- Pre-migration security assessments and guidance to establish best practices.||- Comprehensive review of current and future architectural plans.||- Precise insights into Cloud application usage and associated risks.||Embrace digital transformation securely with Cyber TechGate, ensuring a smooth transition into the Cloud.'
            },
            {
                heading: 'AWS Configuration Security Assessment',
                img: cloud,
                content: 'AWS adoption extends beyond dev/ops teams to encompass organizational assets, including production servers and data. Ensuring adequate security measures within AWS environments is paramount, given its decentralized nature and associated risks.||Our assessment covers key areas:||-Underlying services||-Instances||-Objects||-Networks||-Databases||-Storage Repositories||We evaluate security configurations, identify gaps, conflicts, and unused features, providing insights into AWS utilization and compliance with security controls and deployment practices.||Our experts extend analysis to hybrid environments, integrating findings with on - premise infrastructure for a comprehensive risk assessment.||Reports include:||-Executive summary of AWS security posture||-Configuration baseline export||-Detailed configuration checks and action plan||Trust Cyber TechGate for thorough AWS security assessments, safeguarding your digital assets effectively.'
            },
        ]
    },
    {
        id: 5,
        heading: 'Penetration Testing',
        content: 'Assessing digital defenses through simulated cyber attacks.',
        contents: [
            {
                heading: 'Websites, Mobile Apps, and Infrastructure Security:',
                img: Penetration,
                content: 'Regularly employed by organizations as a vital component of their ongoing security strategy, penetration testing entails our ethical hacking squad executing up-to-date real-world assaults against your security infrastructure, websites, and/or mobile apps. We furnish detailed reports on the findings and recommendations for addressing any detected vulnerabilities, providing insights into your security gaps, current risk profile, and actionable steps for enhancement.||Tests may be conducted externally(as external threats) and/ or internally(as internal threats), offering insights into the effectiveness of existing defense mechanisms and adherence to security best practices.||Cyber TechGate boasts extensive expertise in intricate architecture designs, contemporary attack methodologies, exploits, security loopholes, and digital forensics.This expertise enables us to amalgamate sophisticated penetration testing maneuvers with proprietary forensic techniques for superior outcomes.||Tailoring our methodology based on industry standards such as Penetration Testing Execution Standards(PTES), Open Source Security Testing Methodology Manual(OSSTMM), and Open Web Application Security Project(OWASP), Cyber TechGate adapts to various test targets.||Throughout the penetration testing process, Cyber TechGate delivers a unique, true-to - life simulated hacking campaign experience, leveraging automation, multidisciplinary security expertise, OSINT(Open- source intelligence), and forensics proficiency, augmented by our extensive database of over 9000 exploits.||Our seasoned penetration testing team at Cyber TechGate surpasses mere detection of initial access points or security gaps, actively uncovering hidden risks and threats.||Get in touch with us to discuss your testing requirements.'
            },
            {
                heading: 'Types of Penetration Testing',
                img: response2,
                content: 'White Box Penetration Testing:||Conducted with full knowledge of the target, including source code and architectural review.Suited for comprehensive assessments across all compromise scenarios.||Grey Box Penetration Testing:||Carried out with limited knowledge of the target, focusing on areas deemed high- risk and valuable to potential attackers.Evaluates defensive controls effectiveness and overall security weaknesses.||Black Box Penetration Testing:||Performed with minimal knowledge of the target, simulating an anonymous penetration test.Limited by agreed time and budget constraints, but assesses defensive controls effectiveness against external threats.||Web Application Penetration Testing Options:||Authenticated Testing:||Includes comprehensive scenarios where our team is provided with credentials to conduct extensive testing, covering various authentication levels and associated data security risks.||Unauthenticated Testing:||Used for basic scenarios, focusing on public areas of the target without authentication credentials.||Cyber TechGate recommends discussing key objectives, budget, and time frame with our consultants to determine the most suitable testing approach for your needs.'
            },
        ]
    },
    {
        id: 6,
        heading: 'Cyber Security Consultancy',
        content: 'Tailored guidance to fortify digital defenses and navigate complex security challenges.',
        contents: [
            {
                heading: 'Cyber Security Consulting Services: Elevate Your Defense Strategy',
                img: Consultancy,
                content: 'Every business encounters unique challenges, from regulatory compliance to sophisticated data security threats. In navigating this complex landscape, personalized guidance from a dedicated cyber security engineer is crucial for staying ahead. Yet, without the resources to hire a full-time CISO or security team, identifying hidden vulnerabilities, devising effective action plans, and selecting optimal technologies can be daunting tasks.||With Cyber TechGate, Cyber Security Consulting, you gain access to executive-level counsel without the commitment of new hires. Our solution offers unmatched service, previously available only to larger enterprises. We collaborate with your management team to align security policies and practices with business objectives, empowering you to achieve operational success.||Leverage our extensive expertise to implement tailored cyber security strategies that seamlessly integrate with your business model and budget.Benefit from on- demand support from seasoned consultants, ready to assist whenever you need us.Our specialized services include executive advisory support, vendor assessment, and policy and technical implementation.Whether on a one-time or ongoing basis, we ensure you are equipped to handle the diverse security challenges that arise throughout the year.'
            },
            {
                heading: 'Product Expertise',
                img: Consultancy2,
                content: 'Product-specific Expertise:||In our pursuit of comprehensive security solutions, we recognize the limitations of simple strategies.Thats why we adopt a personalized approach, linking management with specialized consultants tailored to your specific needs. Whether its firewall, intrusion prevention, SIEM(QRadar, Splunk, Log Rhythm, AlienVault), network access control(NAC) security(CISCO ISE, Forescout CounterACT), antivirus, or vulnerability scanning(Qualys, Tenable Nessus, IBM App Scan, Rapid7), we possess extensive expertise in leading security products.Our hands- on experts are adept at implementing these solutions in diverse environments, focusing on security controls and standards specific to your business.||Security Program Design:||In todays digital landscape, safeguarding proprietary and sensitive customer data is paramount. We collaborate with clients to design a robust cyber security framework, encompassing business objectives, risk assessment, tolerance levels, and gap prioritization. Subsequently, we execute your program, ensuring staff are well-versed in policies and best practices, and providing comprehensive training to equip personnel for incident preparedness and response.'
            },
        ]
    },
    // {
    //     id: 7,
    //     heading: 'SEO Service',
    //     content: 'Launch party pitch technology user experience innovator buzz stealth MVP business model.',
    //     contents: [
    //         {
    //             heading: 'Get The Maximum Benefits',
    //             img: seo1,
    //             content: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam'
    //         },
    //         {
    //             heading: 'Get The Maximum Benefits',
    //             img: seo2,
    //             content: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam'
    //         },
    //     ]
    // },
]

export default data;