import React, { useState } from "react";
import emailjs from 'emailjs-com';
import styles from './ContactUsForm.module.css';
import { Snackbar, Alert } from '@mui/material';

const ContactUsForm = () => {
    const [formContent, setFormContent] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormContent(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateForm = (formContent) => {
        let errors = {};

        // Name validation
        if (!formContent.name.trim()) {
            errors.name = 'Name is required';
        }

        // Email validation
        if (!formContent.email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formContent.email)) {
            errors.email = 'Invalid email address';
        }

        // Subject validation
        if (!formContent.subject.trim()) {
            errors.subject = 'Subject is required';
        }

        // Message validation
        if (!formContent.message.trim()) {
            errors.message = 'Message is required';
        }

        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = validateForm(formContent);
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            // Send email if no errors
            const emailParams = {
                user_name: formContent.name,
                user_email: formContent.email,
                message_subject: formContent.subject,
                message: formContent.message,
            };

            emailjs.send('service_fv1xm9s', 'template_w0rz27p', emailParams, 'qhXMQctRf0OSpUNZ_')
                .then((result) => {
                    console.log('Email successfully sent!', result.text);
                    // Clear the form fields
                    setFormContent({
                        name: '',
                        email: '',
                        subject: '',
                        message: '',
                    });
                    // Show a success snackbar
                    setSnackbarMessage('Message sent successfully!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                }, (error) => {
                    console.log('Failed to send email:', error.text);
                    // Handle errors, e.g., showing an error message
                    setSnackbarMessage('Failed to send message. Please try again.');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                });
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <div className={`container ${styles.contain} overflow-hidden`}>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6" data-aos='fade-right' style={{ textAlign: 'left' }}>
                        <label className={styles.label}>Name</label> <label>{errors.name && <div className={styles.errorlabel}>{errors.name}</div>}</label> <br />
                        <input name="name" value={formContent.name} onChange={handleChange} className={styles.input} type="text" />

                        <label className={styles.label}>Email</label> <label>{errors.email && <div className={styles.errorlabel}>{errors.email}</div>}</label> <br />
                        <input name="email" value={formContent.email} onChange={handleChange} className={styles.input} type="email" />

                        <label className={styles.label}>Subject</label> <label>{errors.subject && <div className={styles.errorlabel}>{errors.subject}</div>}</label> <br />
                        <input name="subject" value={formContent.subject} onChange={handleChange} className={styles.input} type="text" />
                    </div>

                    <div className="col-md-6" data-aos='fade-left' style={{ textAlign: 'left' }}>
                        <label className={styles.label}>Message</label> <label>{errors.message && <div className={styles.errorlabel}>{errors.message}</div>}</label> <br />
                        <textarea name="message" value={formContent.message} onChange={handleChange} className={`${styles.input} ${styles.msg}`} />

                        <div style={{ textAlign: 'right' }}>
                            <button type="submit" className={`btn custom_btn ${styles.btn}`}>SEND</button>
                        </div>
                    </div>
                </div>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ContactUsForm;
//email to shahbazchattha