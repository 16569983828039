import CEO from '../../assets/home/Rajanbir.jpg';
import teammember2 from '../../assets/home/Shahbaz.jpg';
import teammember3 from '../../assets/home/harijinder.jpg';

const data = [
    {
        id: 1,
        heading: 'Dr. Shahbaz Chattha',
        content: 'Chief Information Security Officer',
        to: 'https://www.linkedin.com/in/shahbazchattha',
        icon: teammember2,
    },
    {
        id: 2,
        heading: 'Rajanbir Singh Tucker',
        content: 'Managing Director',
        to: 'http://www.linkedin.com/in/rajtucker',
        icon: CEO,
    },
    {
        id: 3,
        heading: 'Harjinder Singh',
        content: 'Director Communication and Public Relations',
        to: 'https://www.linkedin.com/in/harjinder-sadra-174ab0171?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
        icon: teammember3,
    },
]

export default data;