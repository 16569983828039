import React from "react";
import styles from './Top.module.css';
import img1 from '../../../assets/aboutUs/img1.webp';
import img2 from '../../../assets/aboutUs/img2.png';

const Top = () => {
    return (
        <div className={`container overflow-hidden`}>
            <p data-aos='slide-right' className={styles.heading}>About Us</p>
            <p data-aos='slide-left' className={styles.subHeading}>Empower Your Business with Cyber Resilience</p>
            <p data-aos='fade-up' data-aos-offset='80' className={styles.content}>Empower your business with cutting-edge cyber resilience solutions tailored to your needs. Safeguard your digital assets and operations with our expert guidance and support.</p>

            <div className={`row ${styles.contain} flex-column-reverse flex-md-row`}>
                <div data-aos='slide-right' className={`col-md-6`}>
                    <p className={styles.creativity}>Introducing Cyber TechGate</p>
                    <p className={styles.contn}>
                        Cyber TechGate, where safeguarding your business is our top priority. With a proven track record of delivering advisory, assessment, and testing services to diverse government and commercial enterprises across Australia and New Zealand, we stand as a beacon of trust and reliability in the cybersecurity realm.
                        <br />
                        <br />
                        Our regional leadership in enhancing organizational security posture is widely recognized. Each engagement with Cyber TechGate involves crafting a strategic, all-encompassing approach to information governance and IT security, paving the way for comprehensive programmatic enhancements. In today's ever-evolving technological landscape, a static approach to cybersecurity is futile. Thus, we offer dynamic, tactical guidance to ensure continual resilience against cyber threats.
                        <br />
                        <br />
                        Understanding that information technology is the lifeblood of modern enterprises, our consultants excel in communicating effectively with stakeholders at all levels. Whether discussing risk, assurance, governance, or IT operations, we ensure clarity and comprehension throughout the organization.
                        <br />
                        <br />
                        In the realm of cybersecurity, proactive measures are paramount. Don't wait for a cyber incident to disrupt your business. Reach out to Cyber TechGate today for a confidential consultation on how we can fortify your systems, safeguard your data, and protect your people.
                    </p>
                </div>
                <div data-aos='slide-left' data-aos-offset='50' className={`col-md-6 ${styles.imgContain}`}>
                    <img src={img1} alt="" className={styles.img1} />
                </div>
            </div>
            <div data-aos='fade-up' className='row' style={{ textAlign: 'left' }}>
                <div className={`col-md-6`}>
                    <img src={img2} alt="" className={styles.img2} />
                </div>
            </div>
        </div>
    )
}

export default Top;