import React from "react";
import styles from './Middle.module.css';
import logo1 from '../../../assets/aboutUs/middle1.png';
import logo2 from '../../../assets/aboutUs/middle2.png';
import logo3 from '../../../assets/aboutUs/middle3.png';

const Middle = () =>{
    return(
        <div style={{backgroundColor: '#f8f8f8'}} className='overflow-hidden'>
            <div className={`container ${styles.contain}`}>
                <p data-aos='fade-down-right' data-aos-offset="170" className={styles.mainHead}>Advanced Cyber Security Provider</p>
                <p data-aos='fade-up-left' data-aos-offset="170" className={styles.mainContent}>Catalyzing digital resilience, our advanced cybersecurity services redefine protection. Harnessing innovation and expertise, we fortify your defenses against evolving threats, ensuring peace of mind as you navigate the digital frontier.</p>

                <div className="row">
                    <div data-aos='fade-right' data-aos-offset="170" className="col-md-4">
                        <div className={styles.box}>
                            <img src={logo1} alt=""/>
                            <p className={styles.head}>IT Security Audit</p>
                            <p className={styles.content}>Evaluate the complexity and variability of your systems functionality and their management strategies.</p>
                        </div>
                    </div>

                    <div data-aos='fade-up' className="col-md-4">
                        <div className={styles.box}>
                            <img src={logo2} alt=""/>
                            <p className={styles.head}>IT Policy System</p>
                            <p className={styles.content}>Swiftly implement extensive IT policies aligned with benchmark industry norms.</p>
                        </div>
                    </div>

                    <div data-aos='fade-left' className="col-md-4">
                        <div className={styles.box}>
                            <img src={logo3} alt=""/>
                            <p className={styles.head}>Incident Response</p>
                            <p className={styles.content}>What measures has your organization put in place to respond to a malevolent event?</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Middle;